@media only screen and (min-width: 600px) {
    .singleGamePitcherTableContainer td:nth-child(1) {
        position: sticky;
        left: 0;
        z-index: 1;
      }

      .singleGamePitcherTableContainer {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        word-wrap: break-word;
        background-color: #f2f2f2;
      }
    
      .singleGamePitcherTableContainer th {
        font-size: 0.65rem;
        width: 5rem;
      }

      .singleGamePitcherTableContainer td {
        font-size: 0.7rem;
      }
    
      .ratings-container {
        text-align: center;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: 1rem;
      }
      
      .ratings-table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        word-wrap: break-word;
        background-color: #f2f2f2;
      }
    
      .ratings-table thead {
        position: sticky;
        top: 0;
        z-index: 2;
      }
      
      .ratings-table th {
        font-size: 0.65rem;
        width: 3.5rem;
      }

      .ratings-table td {
        font-size: 0.65rem;
      }
}

