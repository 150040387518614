@media only screen and (min-width: 600px) {
    .singleGameHeader {
        text-align: center;
        color: #000055;
        position: relative;
        padding-top: 1rem;
        margin-top: 2rem;
    }

    .teamInisghtsBtnContainer {
        text-align: center;
    }
      
    #teamInsightsBtn {
        text-align: center;
        background-color: #F2F2F2;
        color: navy;
        border: navy solid 0.15rem;
        cursor: pointer;
    }

}