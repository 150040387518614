@media only screen and (min-width: 600px) {
    .hitterTrendsContainerTable {
        width: 99%;
        margin: 0 auto;
        text-align: center;
    }

    .hitterTrendsContainerTable table {
        background-color: #F0F3F4;
        border-collapse: collapse;
        table-layout: fixed;
        box-shadow: 0.35rem 0.35rem 0.35rem 0.35rem rgba(0, 0, 0, 0.15); /* Optional: subtle shadow */
    }

    .hitterTrendsContainerTable th {
        font-size: 0.8rem;
    }

    .hitterTrendsContainerTable td {
        word-wrap: break-word;
        border: 1px solid black;
        font-size: 0.73rem;
        width: 30rem;
    }

    .hitterTrendsContainerTable span {
        font-size: 0.6rem;
    }
}