@media only screen and (min-width: 600px) {
    .pitcherTrendsContainerTable {
        width: 99%;
        margin: 0 auto;
        text-align: center;
    }

    .pitcherTrendsContainerTable table {
        background-color: #F0F3F4;
        border-collapse: collapse;
        table-layout: fixed;
        box-shadow: 0.35rem 0.35rem 0.35rem 0.35rem rgba(0, 0, 0, 0.15); /* Optional: subtle shadow */
    }

    .pitcherTrendsContainerTable th {
        font-size: 0.75rem;
    }

    .pitcherTrendsContainerTable td {
        word-wrap: break-word;
        border: 1px solid black;
        font-size: 0.7rem;
        width: 4rem;
    }

    .pitcherTrendsContainerTable span {
        font-size: 0.6rem;
    }

    .pitcherStrikeoutTrendsContainerTable {
        width: 99%;
        margin: 0 auto;
        text-align: center;
    }

    .pitcherStrikeoutTrendsContainerTable table {
        background-color: #F0F3F4;
        border-collapse: collapse;
        table-layout: fixed;
        box-shadow: 0.35rem 0.35rem 0.35rem 0.35rem rgba(0, 0, 0, 0.15); /* Optional: subtle shadow */
    }

    .pitcherStrikeoutTrendsContainerTable th {
        font-size: 0.8rem;
    }

    .pitcherStrikeoutTrendsContainerTable td {
        word-wrap: break-word;
        border: 1px solid black;
        font-size: 0.73rem;
        width: 10rem;
    }

    .pitcherStrikeoutTrendsContainerTable span {
        font-size: 0.6rem;
    }
}