body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1EEE9;
  /* background-color: #F0F3F4; */
}

@media only screen and (max-width: 600px) {
  .newHomeContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-bottom: 2rem;
  }

  .newHomePremium {
    display: flex;
    margin-top: -1.75rem;
    margin-bottom: 0.5rem;
    width: 90vw;
  }

  .newHomePremium button {
    font-size: 0.65rem;
    width: 5rem;
    height: 2.5rem;
    margin: 0 auto;
    margin-right: 0.5rem;
    margin-top: 1rem
  }

  .newHomePremium button:hover {
    font-size: 0.65rem;
    width: 5rem;
    height: 2.5rem;
    margin: 0 auto;
    margin-right: 0.5rem;
    margin-top: 1rem;
    background: rgba(0, 50, 230, 0.75);
    color: white;
    border: 2px solid gold;
    cursor: pointer;
  }

  .newHomeHeader {
    text-align: center;
    color: black;
    margin-top: 4rem;
  }

  .newHomeHeader h3 {
    margin-top: -1rem;
  }

  .newHomeBoxEven {
    flex: 1;
    height: 20vh;
    width: 100vw;
    background-color: #F0F3F4;
    color: #000055;
  }

  .newHomeBoxEven h2,
  .newHomeBoxEven h3,
  .newHomeBoxEven p,
  .newHomeBoxEven img {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .newHomeBoxOdd {
    flex: 1;
    height: 20vh;
    width: 100vw;
    background-color: #000055;
    color: white;
  }

  .newHomeBoxOdd h2,
  .newHomeBoxOdd p,
  .newHomeBoxOdd img {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .newHomeBoxOdd h3 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: -1rem;
    font-size: medium;
  }

  .newHomeBoxOdd h3:hover {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: -1rem;
    font-size: medium;
    cursor: pointer;
    color: gold
  }

  #graphImage {
    width: 20rem;
  }

  #newHomeTableImage {
    width: 20rem;
  }

  #newHomeTableImage:hover {
    width: 20rem;
    cursor: pointer;
  }

  #pitcherTableImage {
    width: 22rem;
  }

  #tweetImage {
    width: 22rem;
  }

  .logoContainer {
    max-width: 3rem;
    position: relative;
    margin-left: 3rem;
    margin-top: -0.5rem;
  }
  
  .logoContainer img {
    position: absolute;
  }
  
  #logoWords {
    max-width: 20rem;
    min-width: 12rem;
    margin-left: 3.1rem;
    margin-top: 0.75rem;
  }
  
  .signUpButtons {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  
  .signUpButtons :hover {
    background-color: white;
    cursor: pointer;
  }
  
  .getPremiumContainer {
    font-size: 0.85rem;
    margin: 0 auto;
    max-width: 20rem;
    max-height: 2rem;
    display: flex;
    padding: 0.7rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background: rgba(0, 50, 230, 0.25);
    border-radius: var(--radius-rounded-xl, 0.75rem);
    border: 1px solid var(--ring-100, #0032E6);
    margin-bottom: 0.5rem;
    margin-top: -1rem;
  }

  .getPremiumContainer button {
    font-size: 0.75rem;
    margin-right: 1.5rem;
  }

  .ratingsGetPremium {
    font-size: 0.85rem;
    margin: 0 auto;
    max-width: 20rem;
    max-height: 2rem;
    display: flex;
    padding: 0.7rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background: rgba(0, 50, 230, 0.25);
    border-radius: var(--radius-rounded-xl, 0.75rem);
    border: 1px solid var(--ring-100, #0032E6);
    position: relative;
    top: 4rem;
    margin-bottom: 2rem;
  }

  .userNotPremium {
    margin-bottom: 18rem;
  }

  .signUpLoginButtons {
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .signUpLoginButtons button {
    margin-right: 0.5rem;
  }

  .homeContainer {
    display: flex;
  }

  .footerContainer {
    margin-top: 2.5rem;
    height: 3.5rem;
    width: 100vw;
    display: flex;
    color:#000055;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #iconLogoFooter {
    width: 3rem;
    padding-right: 0.5rem;
    margin-left: 2rem;
  }

  .footerLogo {
    display: flex;
    font-size: 0.8rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .contactContainer {
    display: block; 
    font-size: 0.7rem;
    margin-top: -0.5rem;
  }

  .articlesContainer {
    max-width: 15rem;
    display: block;
    margin-right: 1rem;
    margin-left: -0.5rem;
  }

  .homeBreakdownContainer {
    margin-top: 1.2rem;
    text-align: center;
    border: navy solid 0.15rem;
    font-size: 1rem;
    box-shadow: 0.25rem 0.25rem #504b55;
    background-color: #F0F3F4;
  }

  #tableImage {
    margin-top: -1rem;
    margin-bottom: -1rem;
    max-width: 12rem;
  }

  .homePreviewsContainer {
    margin-left: -10rem;
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  .homePreviewsArticle {
    margin-top: 1.2rem;
    text-align: center;
    border: navy solid 0.15rem;
    font-size: 1rem;
    box-shadow: 0.25rem 0.25rem #504b55;
    background-color: #F0F3F4;
    margin-bottom: 1rem;
  }

  .noGamesHomeContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .noGamesPreviewsContainer {
    display: flex;
    gap: 0.5rem;
  }
  
  #newFeaturesImage {
    max-width: 12rem;
  }

  #highlightImage {
    max-width: 12rem;
  }

  .breakdownContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 3.5rem;
  }

  .breakdownContainer h3 {
    text-decoration: underline;
  }

  .paragraphHeader {
    font-weight: bold;
    font-style: italic;
  }

  .twitterLogo {
    max-height: 1.5rem;
    max-width: 1.5rem;
    position: relative;
    top: 0.5rem;
    left: 0.25rem;
  }

  .newFor2024Container {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 3.5rem;
  }

  .newFor2024Container h3 {
    text-decoration: underline;
  }

  .highlightsContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 3.5rem;
  }

  .highlightsContainer ul {
    margin-right: 2rem;
  }

  .highlightsContainer li {
    text-decoration: underline;
  }

  #highlightsDescription {
    font-size: 1rem;
    text-decoration: none;
  }

  .highlightsContainer h3 {
    text-decoration: underline;
  }

  .defaultArticlesContainer {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 22rem;
  }

  .newBreakdownContainer {
    margin-top: 1.2rem;
    text-align: center;
    border: navy solid 0.15rem;
    font-size: 1rem;
    box-shadow: 0.25rem 0.25rem #504b55;
    background-color: #F0F3F4;
    margin-bottom: 3.5rem;
  }
  
  #homeHeader {
    color: #000055;
    text-align: center;
    margin-top: 4rem;
    /* -webkit-text-stroke: 0.05rem black; */
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .loginContainer {
    position: relative;
    top: 3.25rem;
    text-align: center;
    color: #000055;
    margin-bottom: 6rem;
  }

  .signupContainer {
    position: relative;
    top: 3.25rem;
    text-align: center;
    color: #000055;
    margin-bottom: 6rem;
  }

  .table-container {
    max-height: 50rem;
    overflow-y: scroll;
  }
  
  .ratings-dropdown {
    text-align: center;
  }
  
  .ratings-container {
    text-align: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .ratings-table {
    width: 100%;
    word-wrap: break-word;
    white-space: normal;
    font-size: small;
  }

  .ratings-table thead {
    position: sticky;
    top: 0;
    background-color: #F2F2F2;
    z-index: 2;
  }
  
  .ratings-table td, th {
    margin-top: 1rem;
    border: 1px solid;
    text-align: center;
    padding: 0.25rem;
    background-color: #F0F3F4;
  }

  .ratings-table td.favorite {
    border: 2px solid gold;
  }

  .subscriber .ratings-table td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }

  .non-subscriber .ratings-table td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }

  .ratings-table td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }

  .current-ratings-table td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }

  .current-ratings-table {
    width: 100%;
    word-wrap: break-word;
    white-space: normal;
    font-size: small;
  }

  .current-ratings-table thead {
    position: sticky;
    top: 0;
    background-color: #F2F2F2;
    z-index: 2;
  }
  
  .current-ratings-table td, th {
    margin-top: 1rem;
    border: 1px solid;
    text-align: center;
    padding: 0.25rem;
    background-color: #F0F3F4;
  }

  .current-ratings-table td.favorite {
    border: 2px solid gold;
  }

  .pitcher-table-container {
    overflow-y: scroll;
    max-height: 55rem;
  }

  .pitcher-ratings-table {
    width: 100%;
    word-wrap: break-word;
    white-space: normal;
    font-size: small;
    overflow-y: scroll;
  }

  .pitcher-ratings-table .favorite {
    border: 2px solid gold;
  }

  .pitcher-ratings-table thead {
    position: sticky;
    top: 0;
    background-color: #F2F2F2;
    z-index: 2;
  }
  
  .pitcher-ratings-table td, th {
    border: 1px solid;
    text-align: center;
    padding: 0.25rem;
    background-color: #F0F3F4;
  }

  .pitcher-ratings-table td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }

  .singleGamePitcherTableContainer {
    word-wrap: break-word;
    white-space: normal;
    font-size: small;
    overflow: auto;
  }

  .singleGamePitcherTableContainer td, th {
    margin-top: 1rem;
    border: 1px solid;
    text-align: center;
    padding: 0.25rem;
    background-color: #F0F3F4;
  }

  .singleGamePitcherTableContainer td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }

  .singleGameHeader {
    text-align: center;
    color: #000055;
    position: relative;
    top: 3rem;
    padding-bottom: 2.5rem;
    padding-top: 1rem;
    margin-top: 2rem;
  }

  #gameFilter {
    text-align: center;
    color: #000055;
    margin-bottom: -1rem;
  }

  #filterP {
    text-align: center;
    color: #000055;
    margin-bottom: -0.2rem;
  }
  
  .top10Container {
    max-width: 9.5rem;
    padding-right: 1rem;
    margin-left: 1rem;
    text-align: center;
  }
  
  .top10Container h3 {
    margin-bottom: -0.15rem;
    font-size: 0.85rem;
  }
  
  .top10stats {
    list-style: none;
    display: flex;
    border: 1px solid black;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    padding-right: 0.75rem;
    max-width: 10rem;
    margin: auto;
    color: black;
    /* -webkit-text-stroke: 0.01rem white; */
  }
  
  .top10Name {
    width: 7rem;
    font-size: small;
    margin-left: -3rem;
  }
  
  .top10Number {
    font-size: small;
    text-align: center;
  }
  
  .top10Dropdown {
    font-size: 0.9rem;
  }

  .top10Dropdown select {
    width: 9.5rem;
    font-size: 0.8rem;
  }
  
  .favButton {
    font-size: x-small;
    background: rgba(0, 50, 230, 0.25);
  }
  
  .searchBar {
    margin-top: 1rem;
    text-align: center;
  }
  
  .ratings-header {
    text-align: center;
    color: #000055;
    margin-top: -0.75rem;
    padding-top: 3.5rem;
  }
  
  .homeGameBarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    padding: 0.5rem;
  }
  
  .homeGameBar {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    text-align: center;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent line breaks */
    overflow: hidden;
    max-width: 68%;
    position: relative;
  }
  
  .scrollButton {
    position: absolute;
    top: 0; 
    bottom: 0;
    z-index: 1;
  }
  
  .scrollButton-left {
    left: 0;
    font-size: x-small;
    background: rgba(0, 50, 230, 0.25);
  }
  
  .scrollButton-right {
    right: 0;
    font-size: x-small;
    background: rgba(0, 50, 230, 0.25);
  }
  
  .gameBarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0.5rem;
  }
  
  .gameBar {
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
    overflow: hidden;
  }
  
  .gameCards {
    background-color: white;
    color: whitesmoke;
    border: solid 0.15rem navy;
    transition: border-color 0.2s;
    padding: 0.15rem;
    margin: 0.15rem;
    box-shadow: 0.2rem 0.2rem #504b55;
    height: 1.75rem;
    width: 7rem;
    text-align: center;
    cursor: pointer;
    display:inline-block;
    font-size: 0.65rem;
  }
  
  .gameCards:active {
    top: 2px;
    left: 1px;
    box-shadow: none;
    border-color: gold;
  }
  
  .nav {
    font-size: 0.7rem;
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
    padding: 0.25rem;
    max-width: 75%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .subMenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    z-index: 1000;
  }

  .navLink:hover .subMenu {
      display: block;
  }

  .subNavLink {
      display: block;
      padding: 10px;
      text-decoration: none;
      color: #333;
  }

  .subNavLink:hover {
      background-color: #f0f0f0;
  }

  .getPWResetLinkContainer {
    position: relative;
    top: 3.25rem;
    text-align: center;
    color: #000055;
    margin-bottom: 25rem;
  }

  .getPWResetLinkContainer button {
    margin-left: 0.5rem;
  }

  .resetPWFormContainer {
    position: relative;
    top: 3.25rem;
    text-align: center;
    color: #000055;
    margin-bottom: 18rem;
  }
  
  .featureComparisonContainer {
    max-width: 98%;
    margin-left: 0.5rem;
  }

  .featureComparisonContainer li {
    list-style:disc;
  }
  
  .freeTier ul {
    text-align: left;
    margin-top: -1rem;
  }

  .premiumTier ul {
    text-align: left;
    margin-top: -1rem;
  }

  .example {
    max-width: 90%;
  }

  .example:not(.example li) {
    list-style-type: square;
    margin-left: 1.2rem;
  }

  #everythingInFree {
    margin-top: -1rem;
  }

  .checkoutFormContainer {
    /* position: relative;
    top: 1rem;
    left: 0.5rem; */
    margin-top: 1rem;
    margin-left: 0.5rem;
    margin-bottom: 4rem;
    margin-right: 0.5rem;
  }

  .checkoutFormContainer label {
    margin-right: 0.25rem;
  }

  .checkoutFormContainer select {
    position: relative;
    left: 0.5rem;
  }

  .checkoutContainer {
    position: relative;
    top: 3.5rem;
  }

  #totalInput {
    width: 2rem;
  }

  .paymentElement {
    margin-top: 0.75rem;
  }

  .hitterTrends {
    text-align: center;
    position: relative;
    top: 3.5rem;
    margin-bottom: 40rem;
  }

  .searchSuggestions {
    background-color: white;
    width: 10rem;
    overflow-y: scroll;
    max-height: 6rem;
    z-index: 1;
    position: absolute;
    left: 55%;
    transform: translateX(-50%);
  }

  .searchSuggestions ul {
    text-align: left;
    margin-top: 0rem;
    padding-right: 0.5rem;
    padding-bottom: -1rem;
  }

  .searchLi {
    list-style: none;
  }

  .hitterResultsContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .hitterResultsContainer table {
    background-color: #F0F3F4;
    border-collapse: collapse;
  }

  .hitterResultsContainer td {
    word-wrap: break-word;
    white-space: normal;
    border: 1px solid black;
    font-size: 0.85rem;
  }

  .graphContainer {
    text-align: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 110vh;
    width: 100vw;
    margin-bottom: -30rem;
  }

  .hitterGraph {
    display: block;
    text-align: center;
    background-color: #F0F3F4;
    width: 95vw;
    height: 90vh;
  }
  
  .pitcherGraphContainer {
    height: 75vh;
    width: 95vw;
    text-align: center;
    margin-bottom: -25rem;
  }

  .pitcherGraph {
    display: block;
    background-color: #F0F3F4;
    width: 95vw;
    height: 70vh;
  }

  .subscriptionTypeContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100vw;
  }

  .dayPassContainer {
    background-color: #000055;
    color: white;
    text-align: center;
    width: 100vw;
  }

  .seasonPassContainer {
    background-color: #F0F3F4;
    color: #000055;
    text-align: center;
    width: 100vw;
  }

  .monthPassContainer {
    border: 1px black solid;
    background: rgba(0, 50, 230, 0.25);
    box-shadow: 0.2rem 0.2rem #504b55;
  }
  /* ul, li {
    list-style: none;
  } */

  #color-key {
    padding-right: 0.5rem;
  }

  .colorLabels {
    display: inline-flex;
    font-size: x-small;
    justify-content: center;
    list-style: none;
  }

  .centerColors {
    text-align: center;
  }
  
  #elite {
    color: lightblue;
    -webkit-text-stroke: 0.015rem black;
    padding-right: 0.5rem;
  }
  
  #great {
    color: lightgreen;
    -webkit-text-stroke: 0.015rem black;
    padding-right: 0.5rem;
  }
  
  #above-average {
    color: #ffff00;
    -webkit-text-stroke: 0.017rem black;
    padding-right: 0.5rem;
  }
  
  #poor {
    color: lightpink;
    -webkit-text-stroke: 0.015rem black;
    padding-right: 0.5rem;
  }
  
  #sortableColor {
    color: #bf94e4;
    -webkit-text-stroke: 0.015rem black;
  }

  .sortable:hover {
    cursor: pointer;
  }
  
  .sortable {
    background-color: #d0b9e4;
  }

  .notPremium {
    position: relative;
    top: 3.5rem;
    text-align: center;
    color: #000055;
  }

  .errors {
    color: red;
  }

  .singleGameGetPremium {
    position: relative;
    top: 0.5rem;
    margin-bottom: 4rem;
  }

  .singleGameGetPremiumHeader {
    text-align: center;
    color: #000055;
    margin-top: -2.5rem;
  }

  .alreadyPremium {
    text-align: center;
    color: #000055;
    margin-bottom: 25rem;
    position: relative;
    top: 3.5rem;
  }
  
  .checkoutNotSignedIn {
    text-align: center;
    color: #000055;
    position: relative;
    top: 3rem;
    margin-bottom: 4rem;
  }

  .notSignedInBtns {
    justify-content: center;
    display: flex;
    margin-top: -0.5rem;
  }

  .articles {
    text-align: center;
    background-color: #F2F2F2;
    max-width: 75%;
    box-shadow: 3px 4px #504b55;
    border: navy solid 0.15rem;
    padding: 0.5rem;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }

  .picks {
    text-align: center;
    margin-bottom: 25rem;
  }
  
  .pickNames {
    text-align: center;
    display: inline-flex;
  }
  
  .pickNames img {
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
  }

  #overallRecords {
    margin-right: 0.5rem;
  }

  .userFavoritesContainer {
    text-align: center;
    margin-bottom: 5rem;
  }

  .userSettings {
    text-align: center;
    margin-bottom: 40%;
  }

  .userIconContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0.25rem;
    margin-right: 0.25rem;
    width: 1.75rem;
    height: 1.75rem;
    background: rgba(0, 50, 230, 0.25);
    border-radius: var(--radius-rounded-xl, 0.75rem);
    border: 1px solid var(--ring-100, #0032E6);
    cursor: pointer;
  }

  .iconDropDownContainer {
      display: none;
      position: absolute;
      top: calc(100% + 0.5rem); /* Position it below the initial */
      right: 0;
      padding: 0.5rem;
      font-size: 0.7rem;
  }

  .userIconContainer.open .iconDropDownContainer {
      display: block;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
      z-index: 1000;
  }

  .iconDropDownContainer p {
    border: 1px solid #000055;
    padding: 0.25rem;
  }

  .iconDropDownContainer p:hover{
    border-color: gold;
  }

  #last10EliteImage {
    max-width: 16rem;
  }

  #eliteGraphImage {
    max-width: 14rem;
  }

  .favorite {
    border: 2px solid gold;
  }

  .about {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: -22rem;
  }

  .about h1 {
    font-size: 1.9rem;
    color: #000055;
  }

  .FAQs {
    margin-left: 1rem;
    margin-right: 1.5rem;
  }

  .FAQH2 {
    color: #000055;
  }

  #FAQH1 {
    color: #000055;
  }

  .agreements {
    font-size: 0.8rem;
    display: flex;
    gap: 1rem;
    color: #000055;
    position: relative;
    justify-content: center;
    margin-top: -1rem;
  }

  .terms {
    margin-bottom: 2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .privacy {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 2rem;
  }

  .glossary {
    text-align: center;
    max-width: 100%;
    max-height: 15rem;
    overflow-y: auto;
    margin-top: 0.5rem;
  }

  .glossary ul {
    list-style: none;
  }
  
  .glossaryList {
    text-align: left;
  }
  
  #glossaryBtn {
    text-align: center;
    background-color: #F2F2F2;
    color: navy;
    border: navy solid 0.15rem;
    cursor: pointer;
  }

  #glossaryCourtesy {
    text-align: left;
    font-size: x-small;
    margin-left: 2.5rem;
  }
  
  #glossaryTerm {
    text-decoration: underline;
  }

  .favTableContainer {
    display: inline-block;
    justify-content: center;
  }
  
  .favoriteTable {
    text-align: center;
    background-color: #F0F3F4;
    word-wrap: break-word;
    white-space: normal;
    border-collapse: collapse;
    font-size: small;
  }

  .favoriteTable td {
    border: solid 1px black;
  }
}

.errors {
  color: red;
}

.spinner {
  border: 1rem solid rgba(0, 0, 0, 0.1);
  border-top-color: navy;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  animation: spin 1s linear infinite;
  position: relative;
  top: 48.5%;
  left: 47%;
}

.announcementPopUp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popUpContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  max-width: 60%;
  font-size: smaller;
}

.popUpContent h2 {
  margin-top: 0;
}

.popUpContent button {
  margin-top: 20px;
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popUpContent button:hover {
  background: #0056b3;
}

.salePopUp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.salePopUpContent {
  background: #BD3039;
  color: white;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: left;
  max-width: 60%;
  font-size: smaller;
}

.salePopUpContent h3 {
  font-size: large;
  color: gold;
}

#closeSaleButton {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 28vh;
  right: 15vw;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
}

#salePopUpPremiumButton {
  font-size: 0.65rem;
  width: 5rem;
  height: 2rem;
  margin: 0 auto;
}

#salePopUpPremiumButton:hover {
  font-size: 0.65rem;
  width: 5rem;
  height: 2.5rem;
  margin: 0 auto;
  background: rgba(0, 50, 230, 0.75);
  color: white;
  border: 2px solid gold;
  cursor: pointer;
}

#boldP {
  font-weight: 600;
}

.freePopUp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.freePopUpContent {
  background-image: linear-gradient(to right, #003087 , #005A9C);
  color: white;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: left;
  max-width: 60%;
  font-size: smaller;
}

.freePopUpContent h3 {
  font-size: large;
  color: gold;
}

#closeFreeButton {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 20vh;
  right: 15vw;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
/*========================== SCREEN SIZE 600PX + BELOW ======================================  */

@media only screen and (min-width: 600px) {
  .hitterPageBox {
    display: inline-block;
    margin-top: 3.25rem;
    margin-bottom: 1rem;
    margin-left: 10rem;
    margin-right: 5rem;
    max-width: 90%;
    min-width: 80%;
    height: 8rem;
    padding-left: 0.5rem;
    box-shadow: 0.35rem 0.35rem 0.35rem rgba(0, 0, 0, 0.15); /* Optional: subtle shadow */
  }

  .hitterPageDetails {
    max-width: 90%;
  }

  .hitterPageName {
    font-size: 2rem;
    margin-top: 0.4rem
  }

  .hitterPageDetails h4 {
    margin-top: -1.25rem;
    font-size: 1rem;
  }

  .hitterPageStats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: -0.5rem; /* Space between columns */
    max-width: 90%; /* Optional: to keep the layout compact */
    margin-left: 10rem;
    margin-right: 5rem;
    box-shadow: 0.35rem 0.35rem 0.35rem rgba(0, 0, 0, 0.15); /* Optional: subtle shadow */
    background-color: #F0F3F4;
  }

  .hitterPageStats h3 {
      grid-column: span 2; /* Make the header span both columns */
      text-align: center; /* Center the heading */
      margin-bottom: 0.5rem;
      font-size: 1.75rem;
  }

  .hitterPageStats ul {
      list-style-type: none; /* Remove bullets */
      margin: 0;
      padding: 0;
      display: grid;
      gap: 0.15rem;
      margin-bottom: 0.75rem
  }

  .hitterPageStats li {
      display: flex;
      justify-content: space-between; /* Space between label and value */
      font-size: 1.2rem;
      margin-left: 3rem;
      margin-bottom: 0.15rem;
      align-items: center;
  }

  .hitterPageStats li span {
    margin-right: 8rem;
    font-size: 1.2rem;
  }

  .pitcherPageBox {
    display: inline-block;
    margin-top: 5rem;
    margin-bottom: 1rem;
    margin-left: 3.5rem;
    width: 90%;
    height: 8rem;
    padding-left: 0.5rem;
    box-shadow: 0.35rem 0.35rem 0.35rem rgba(0, 0, 0, 0.15); /* Optional: subtle shadow */
  }

  .pitcherPageName {
    font-size: 2rem;
    margin-top: 0.4rem
  }

  .pitcherPageDetails h4 {
    margin-top: -1.25rem;
    font-size: 1rem;
  }

  .pitcherPageStats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%; /* Optional: to keep the layout compact */
    margin-left: 3.5rem;
    box-shadow: 0.35rem 0.35rem 0.35rem rgba(0, 0, 0, 0.15); /* Optional: subtle shadow */
    background-color: #F0F3F4;
  }

  .pitcherPageStats h3 {
    grid-column: span 2; /* Make the header span both columns */
    text-align: center; /* Center the heading */
    margin-bottom: 0.5rem;
    font-size: 1.75rem;
  }

  .pitcherPageStats ul {
    list-style-type: none; /* Remove bullets */
    margin: 0;
    padding: 0;
    display: grid;
    gap: 0.15rem;
    margin-bottom: 0.75rem
  }

  .pitcherPageStats li {
    display: flex;
    justify-content: space-between; /* Space between label and value */
    font-size: 1.15rem;
    margin-left: 2rem;
    margin-bottom: 0.15rem;
    align-items: center;
  }

  .pitcherPageStats li span {
    margin-right: 3rem;
    font-size: 1.2rem;
  }

  .freePopUp {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .freePopUpContent {
    background-image: linear-gradient(to right, #003087 , #005A9C);
    color: white;
    padding: 1.5rem;
    border-radius: 8px;
    text-align: left;
    max-width: 60%;
    font-size: larger;
  }
  
  .freePopUpContent h3 {
    font-size: larger;
    color: gold;
  }
  
  #closeFreeButton {
    position: fixed;
    top: 20vh;
    right: 25vw;
    margin-top: 0.25rem;
    margin-right: 0.25rem;
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
  }

  .newHomeContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-bottom: 2rem;
  }

  .newHomePremium {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 38vw;
  }

  .newHomePremium button {
    font-size: 0.65rem;
    width: 5rem;
    height: 2rem;
    margin-top: 0.7rem;
  }

  .newHomePremium button:hover {
    font-size: 0.65rem;
    width: 5rem;
    height: 2rem;
    margin: 0 auto;
    margin-top: 0.7rem;
    background: rgba(0, 50, 230, 0.75);
    color: white;
    border: 2px solid gold;
    cursor: pointer;
  }

  .newHomeHeader {
    text-align: center;
    margin-top: 4rem;
    color: #000055;
  }

  .newHomeHeader h3 {
    margin-top: -1rem;
  }

  .newHomeBoxEven {
    flex: 1;
    height: 20vh;
    width: 100vw;
    background-color: #F0F3F4;
    color: #000055;
  }

  .newHomeBoxEven h2,
  .newHomeBoxEven h3,
  .newHomeBoxEven p,
  .newHomeBoxEven img {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .newHomeBoxOdd {
    flex: 1;
    height: 20vh;
    width: 100vw;
    background-color: #000055;
    color: white;
  }

  .newHomeBoxOdd h2,
  .newHomeBoxOdd p,
  .newHomeBoxOdd img {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .newHomeBoxOdd h3 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: -1rem;
    font-size: large;
  }

  .newHomeBoxOdd h3:hover {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: -1rem;
    font-size: large;
    cursor: pointer;
    color: gold
  }

  #graphImage {
    width: 30rem;
  }

  #newHomeTableImage {
    width: 30rem;
  }

  #newHomeTableImage:hover {
    width: 30rem;
    cursor: pointer;
  }

  #newHomeTableP:hover {
    cursor: pointer;
    color: gold
  }

  #pitcherTableImage {
    width: 60rem;
  }

  #tweetImage {
    width: 30rem;
  }

  .salePopUp {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .salePopUpContent {
    background: #BD3039;
    color: white;
    padding: 1.5rem;
    border-radius: 8px;
    text-align: left;
    max-width: 60%;
    font-size: smaller;
  }
  
  .salePopUpContent h3 {
    font-size: large;
    color: gold;
  }
  
  #closeSaleButton {
    position: fixed;
    top: 28vh;
    right: 28vw;
    margin-top: 0.25rem;
    margin-right: 0.25rem;
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
  }
  
  #salePopUpPremiumButton {
    font-size: 0.65rem;
    width: 5rem;
    height: 2rem;
    margin: 0 auto;
  }
  
  #salePopUpPremiumButton:hover {
    font-size: 0.65rem;
    width: 5rem;
    height: 2.5rem;
    margin: 0 auto;
    background: rgba(0, 50, 230, 0.75);
    color: white;
    border: 2px solid gold;
    cursor: pointer;
  }

  .announcementPopUp {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .popUpContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    max-width: 50%;
    font-size: medium;
  }

  .popUpContent h2 {
    margin-top: 0;
  }
  
  .popUpContent button {
    margin-top: 20px;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popUpContent button:hover {
    background: #0056b3;
  }

  #boldP {
    font-weight: 600;
  }

  .waysToPayHeader {
    text-align: center;
    color: #000055;
  }

  .favTableContainer {
    display: inline-block;
    justify-content: center;
  }
  
  .favoriteTable {
    text-align: center;
    background-color: #F0F3F4;
    word-wrap: break-word;
    white-space: normal;
    border-collapse: collapse;
  }

  .favoriteTable td {
    border: solid 1px black;
  }

  .glossary {
    text-align: center;
    max-width: 100%;
    max-height: 15rem;
    overflow-y: auto;
    margin-top: 0.5rem;
  }

  .glossary ul {
    list-style: none;
  }
  
  .glossaryList {
    text-align: left;
  }
  
  #glossaryBtn {
    text-align: center;
    background-color: #F2F2F2;
    color: navy;
    border: navy solid 0.15rem;
    cursor: pointer;
  }

  #glossaryCourtesy {
    text-align: left;
    font-size: x-small;
    margin-left: 2.5rem;
  }
  
  #glossaryTerm {
    text-decoration: underline;
  }

  .agreements {
    font-size: 0.8rem;
    display: flex;
    gap: 1rem;
    color: #000055;
    position: relative;
    justify-content: center;
    margin-top: -1rem;
  }

  .terms {
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .privacy {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .FAQs {
    margin-left: 1rem;
    margin-right: 3rem;
  }

  .FAQH2 {
    color: #000055;
  }

  #FAQH1 {
    color: #000055;
  }

  .about {
    margin-left: 1rem;
    margin-right: 3rem;
    margin-bottom: -20rem;
  }

  .about h1 {
    color: #000055;
  }

  .singleGamePitcherTableContainer td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }

  .articles {
    text-align: center;
    background-color: #F2F2F2;
    max-width: 75%;
    box-shadow: 3px 4px #504b55;
    border: navy solid 0.15rem;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .picks {
    text-align: center;
    margin-bottom: 25rem;
  }
  
  .pickNames {
    text-align: center;
    display: inline-flex;
  }
  
  .pickNames img {
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
  }

  #overallRecords {
    margin-right: 0.5rem;
  }

  .getPWResetLinkContainer {
    position: relative;
    top: 3.25rem;
    text-align: center;
    color: #000055;
    margin-bottom: 20rem;
  }

  .getPWResetLinkContainer button {
    margin-left: 0.5rem;
  }

  .resetPWFormContainer {
    position: relative;
    top: 3.25rem;
    text-align: center;
    color: #000055;
    margin-bottom: 16rem;
  }

  .checkoutNotSignedIn {
    color: #000055;
    text-align: center;
    position: relative;
    margin-bottom: 1rem;
  }

  .checkoutNotSignedIn button {
    margin-right: 0.5rem;
  }

  .checkoutContainer {
    position: relative;
    top: 3.5rem;
  }

  .checkoutFormContainer {
    /* position: relative;
    top: 1rem;
    left: 0.5rem; */
    margin-top: 1rem;
    margin-left: 0.5rem;
    margin-bottom: 4rem;
    margin-right: 0.5rem;
  }

  .checkoutFormContainer label {
    margin-right: 0.25rem;
  }

  .checkoutFormContainer select {
    position: relative;
    left: 0.5rem;
  }

  .colorLabels {
    display: inline-flex;
    list-style: none;
  }

  .centerColors {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .subMenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    z-index: 1000;
  }

  .navLink:hover .subMenu {
      display: block;
  }

  .subNavLink {
      display: block;
      padding: 10px;
      text-decoration: none;
      color: #333;
  }

  .subNavLink:hover {
      background-color: #f0f0f0;
  }

  .logoContainer {
    max-width: 5rem;
    min-width: 2rem;
    position: relative;
    margin-left: 3rem;
  }
  
  .logoContainer img {
    position: absolute;
    top: -1rem;
  }
  
  #logoWords {
    width: 12rem;
    margin-left: 5.5rem;
    margin-top: 2rem;
  }

  .signUpLoginButtons {
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .signUpLoginButtons button {
    margin-right: 0.5rem;
  }

  .loginContainer {
    position: relative;
    top: 3.25rem;
    text-align: center;
    color: #000055;
    margin-bottom: 7rem;
  }

  .signupContainer {
    position: relative;
    top: 3.25rem;
    text-align: center;
    color: #000055;
    margin-bottom: 7rem;
  }
  
  .getPremiumContainer {
    margin: 0 auto;
    max-width: 35rem;
    max-height: 2rem;
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    /* background-color: #476FFF; */
    background: rgba(0, 50, 230, 0.25);
    border-radius: var(--radius-rounded-xl, 0.75rem);
    border: 1px solid var(--ring-100, #0032E6);
    margin-bottom: 1rem;
  }
  
  .getPremiumContainer button:hover {
    background-color: white;
    cursor: pointer;
  }

  .alreadyPremium {
    text-align: center;
    color: #000055;
    margin-bottom: 25rem;
    position: relative;
    top: 3.5rem;
  }
  
  #homeHeader {
    color: black;
    text-align: center;
  }

  .signUpLoginButtons {
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .signUpLoginButtons button {
    margin-right: 0.5rem;
  }

  .footerContainer {
    height: 3.5rem;
    width: 100%;
    text-align: center;
    color:#000055;
    margin-top: 1rem;
  }

  #iconLogoFooter {
    width: 3rem;
    padding-right: 0.5rem;
  }
  
  .footerLogo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .userSettings {
    text-align: center;
    margin-bottom: 40%;
  }

  .userIconContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0.25rem;
    margin-right: 0.25rem;
    width: 2rem;
    height: 2rem;
    background: rgba(0, 50, 230, 0.25);
    border-radius: var(--radius-rounded-xl, 0.75rem);
    border: 1px solid var(--ring-100, #0032E6);
    cursor: pointer;
  }

  .iconDropDownContainer {
      display: none;
      position: absolute;
      top: calc(100% + 0.5rem); /* Position it below the initial */
      right: 0;
      padding: 0.5rem;
      font-size: 0.7rem;
  }

  .userIconContainer.open .iconDropDownContainer {
      display: block;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
      z-index: 1000;
  }

  .iconDropDownContainer p {
    border: 1px solid #000055;
    padding: 0.25rem;
  }

  .iconDropDownContainer p:hover{
    border-color: gold;
  }

  .userFavoritesContainer {
    text-align: center;
    margin-bottom: 5rem;
  }

  .contactContainer {
    display: block; 
    font-size: 0.8rem;
    margin-top: -0.5rem;
  }

  .homeContainer {
    display: flex;
  }

  .articlesContainer {
    max-width: 50%;
    display: block;
    margin-right: 1rem;
    margin-left: 6rem;
  }

  .homeBreakdownContainer {
    margin-top: 1.2rem;
    text-align: center;
    border: navy solid 0.15rem;
    font-size: 1.2rem;
    box-shadow: 0.25rem 0.25rem #504b55;
    background-color: #F0F3F4;
    margin-right: 8rem;
  }

  #tableImage {
    margin-top: -1rem;
    margin-bottom: -1rem;
    max-width: 15rem;
  }

  #last10EliteImage {
    max-width: 30rem;
  }

  #eliteGraphImage {
    max-width: 25rem;
  }

  .homePreviewsContainer {
    margin-left: -10rem;
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  .homePreviewsArticle {
    margin-top: 1.2rem;
    text-align: center;
    border: navy solid 0.15rem;
    font-size: 1.2rem;
    box-shadow: 0.25rem 0.25rem #504b55;
    background-color: #F0F3F4;
    margin-bottom: 1rem;
  }

  .noGamesHomeContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .noGamesPreviewsContainer {
    display: flex;
    gap: 0.5rem;
  }
  
  #newFeaturesImage {
    max-width: 15rem;
  }

  #highlightImage {
    max-width: 15rem;
  }

  .breakdownContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 3.5rem;
  }

  .breakdownContainer h3 {
    text-decoration: underline;
  }

  .paragraphHeader {
    font-weight: bold;
    font-style: italic;
  }

  .twitterLogo {
    max-height: 1.5rem;
    max-width: 1.5rem;
    position: relative;
    top: 0.5rem;
    left: 0.25rem;
  }

  .newFor2024Container {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 3.5rem;
  }

  .newFor2024Container h3 {
    text-decoration: underline;
  }

  .highlightsContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 3.5rem;
  }

  .highlightsContainer ul {
    margin-right: 2rem;
  }

  .highlightsContainer li {
    text-decoration: underline;
  }

  #highlightsDescription {
    font-size: 1rem;
    text-decoration: none;
  }

  .highlightsContainer h3 {
    text-decoration: underline;
  }

  .defaultArticlesContainer {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 22rem;
  }

  .newBreakdownContainer {
    margin-top: 1.2rem;
    text-align: center;
    border: navy solid 0.15rem;
    font-size: 1rem;
    box-shadow: 0.25rem 0.25rem #504b55;
    background-color: #F0F3F4;
    margin-bottom: 3.5rem;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .ratings-dropdown {
    text-align: center;
  }

  .table-container {
    max-height: 50rem;
    overflow-x: scroll;
  }
  
  .ratings-container {
    text-align: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .ratings-table {
    width: 100%;
    word-wrap: break-word;
    white-space: normal;
    font-size: small;
  }

  .ratings-table thead {
    position: sticky;
    top: 0;
    background-color: #F2F2F2;
    z-index: 2;
  }
  
  .ratings-table td, th {
    margin-top: 1rem;
    border: 1px solid;
    text-align: center;
    padding: 0.25rem;
    background-color: #F0F3F4;
  }

  .ratings-table td.favorite {
    border: 2px solid gold;
  }

  .favorite {
    border: 2px solid gold;
  }

  .current-ratings-table td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }
   
  .current-ratings-table {
    width: 100%;
    word-wrap: break-word;
    white-space: normal;
    font-size: small;
  }

  .current-ratings-table thead {
    position: sticky;
    top: 0;
    background-color: #F2F2F2;
    z-index: 2;
  }
  
  .current-ratings-table td, th {
    margin-top: 1rem;
    border: 1px solid;
    text-align: center;
    padding: 0.25rem;
    background-color: #F0F3F4;
  }

  .current-ratings-table td.favorite {
    border: 2px solid gold;
  }

  .subscriber .ratings-table td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }

  .non-subscriber .ratings-table td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }

  .ratingsGetPremium {
    font-size: 0.85rem;
    margin: 0 auto;
    max-width: 20rem;
    max-height: 2rem;
    display: flex;
    padding: 0.7rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background: rgba(0, 50, 230, 0.25);
    border-radius: var(--radius-rounded-xl, 0.75rem);
    border: 1px solid var(--ring-100, #0032E6);
  }

  .userNotPremium {
    margin-bottom: 18rem;
  }

  .pitcher-table-container {
    overflow: auto;
    max-height: 55rem;
  }

  .pitcher-ratings-table {
    width: 100%;
    word-wrap: break-word;
    white-space: normal;
    font-size: small;
    overflow-y: scroll;
  }

  .pitcher-ratings-table .favorite {
    border: 2px solid gold;
  }

  .pitcher-ratings-table thead {
    position: sticky;
    top: 0;
    background-color: #F2F2F2;
    z-index: 2;
  }
  
  .pitcher-ratings-table td, th {
    border: 1px solid;
    text-align: center;
    padding: 0.25rem;
    background-color: #F0F3F4;
  }

  .pitcher-ratings-table td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }

  .singleGamePitcherTableContainer {
    word-wrap: break-word;
    white-space: normal;
    font-size: small;
    overflow: auto;
  }

  .singleGamePitcherTableContainer td, th {
    margin-top: 1rem;
    border: 1px solid;
    text-align: center;
    padding: 0.25rem;
    background-color: #F0F3F4;
  }

  .singleGamePitcherTableContainer td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }

  .singleGameHeader {
    text-align: center;
    color: #000055;
    position: relative;
    padding-top: 1rem;
    margin-top: 2rem;
  }

  #gameFilter {
    text-align: center;
    color: #000055;
    margin-bottom: -1rem;
  }

  #filterP {
    text-align: center;
    color: #000055;
    margin-bottom: -0.2rem;
  }
  
  .homeContainer {
    display: flex;
  }
  
  .top10Container {
    width: 25%;
    padding-right: 2rem;
    margin-left: 2rem;
    text-align: center;
    /* background-color: black;
    color: white; */
  }
  
  .top10Container h3 {
    margin-bottom: -0.15rem;
  }
  
  .top10stats {
    list-style: none;
    display: flex;
    border: 1px solid black;
    padding-bottom: 0.35em;
    padding-top: 0.35rem;
    max-width: 10rem;
    margin: auto;
    color: black;
    /* -webkit-text-stroke: 0.01rem white; */
  }
  
  .top10Name {
    width: 8rem;
    font-size: small;
    margin-left: -2rem;
  }
  
  .top10Number {
    font-size: small;
    text-align: center;
  }
  
  .favButton {
    font-size: x-small;
    background: rgba(0, 50, 230, 0.25);
  }
  
  .searchBar {
    margin-top: 1rem;
    text-align: center;
  }
  
  .ratings-header {
    text-align: center;
    color: #000055;;
  }
  
  .homeGameBarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0.5rem;
  }
  
  .homeGameBar {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent line breaks */
    overflow: hidden;
    max-width: 85%;
    position: relative;
  }
  
  .scrollButton {
    position: absolute;
    top: 0; 
    bottom: 0;
    z-index: 1;
  }
  
  .scrollButton-left {
    left: 0;
    font-size: large;
    background: rgba(0, 50, 230, 0.25);
  }
  
  .scrollButton-right {
    right: 0;
    font-size: large;
    background: rgba(0, 50, 230, 0.25);
  }
  
  .gameBarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0.5rem;
  }
  
  .gameBar {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    overflow: hidden;
  }
  
  .gameCards {
    background-color: white;
    color: whitesmoke;
    border: solid 0.15rem navy;
    transition: border-color 0.2s;
    padding: 0.25rem;
    margin: 0.25rem;
    box-shadow: 0.25rem 0.25rem #504b55;
    height: 2.5rem;
    width: 13rem;
    text-align: center;
    cursor: pointer;
    display:inline-block;
    font-size: 0.9rem;
  }
  
  .gameCards:hover {
    border-color: gold;
    /* background: rgba(0, 50, 230, 0.25); */
  }
  
  .gameCards:active {
    top: 2px;
    left: 1px;
    box-shadow: none;
  }
  
  .nav {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem calc((90vw - 30rem) / 2);
  }
  
  .hitterTrends {
    text-align: center;
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    max-width: 90%;
    margin-left: 10rem;
    margin-right: 5rem;
    margin-bottom: 2rem;
    box-shadow: 0.35rem 0.35rem 0.35rem rgba(0, 0, 0, 0.15); /* Optional: subtle shadow */
    background-color: #F0F3F4;
    padding-bottom: 1rem;
  }

  .hitterGraph {
    display: block;
    width: 100%;
    height: 70vh;
    margin: 0 auto;
  }

  .hitterGraphContainer h2 {
    margin-bottom: -1rem;
    font-size: 1.2rem;
  }

  .hitterGraphContainer p {
    margin-bottom: -1rem;
  }

  .hitterResultsContainer {
    text-align: center;
    margin-left: 1rem;
  }

  .hitterResultsContainer h2 {
    font-size: 1.2rem;
  }

  .hitterResultsContainer table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 20rem;
    margin: 0 auto;
  }

  .hitterResultsContainer th {
    font-size: 0.7rem;
    text-align: center;
    width: 4.5rem;
  }

  .hitterResultsContainer td {
    word-wrap: break-word;
    white-space: normal;
    border: 1px solid black;
    font-size: 0.75rem;
  }

  .pitcherGraphContainer {
    height: 75vh;
    width: 100%;
    text-align: center;
    margin-bottom: 2rem
  }

  .pitcherGraphContainer h2 {
    margin-bottom: -1rem;
    font-size: 1.2rem;
  }

  .pitcherGraphContainer p {
    margin-bottom: -1rem;
  }

  .pitcherGraph {
    display: block;
    width: 50vw;
    height: 90vh;
  }

  .pitcherTrends {
    text-align: center;
    margin-bottom: 40%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    width: 90%;
    margin-left: 3.5rem;
    margin-bottom: 2rem;
    box-shadow: 0.35rem 0.35rem 0.35rem rgba(0, 0, 0, 0.15); /* Optional: subtle shadow */
    background-color: #F0F3F4;
  }

  .pitcherMatchupRatingSection {
    margin-bottom: 5rem;
  }

  .pitcherStrikeoutRatingSection {
    margin-bottom: 1rem;
  }

  .pitcherStrikeoutRatingSection h2 {
    text-align: center;
    font-size: 1.2rem;
  }

  .pitcherResultsContainer {
    margin-left: 1rem;
  }

  .pitcherResultsContainer h2 {
    text-align: left;
    font-size: 1.2rem;
  }

  .pitcherResultsContainer table {
    border-collapse: collapse;
  }

  .pitcherResultsContainer td {
    word-wrap: break-word;
    white-space: normal;
    border: 1px solid black;
    font-size: 0.75rem;
  }

  .searchSuggestions {
    background-color: white;
    max-width: 12rem;
    max-height: 6rem;
    overflow-y: scroll;
    z-index: 1;
    position: absolute;
    left: 55%;
    transform: translateX(-50%);
  }

  .searchSuggestions ul {
    text-align: left;
    margin-top: 0rem;
  }

  .searchLi {
    list-style: none;
  }

  #everythingInFree {
    margin-top: -1rem;
  }

  .example {
    max-width: 90%;
    list-style-type: square;
    margin-left: 1.2rem;
  }

  .premiumTier ul {
    text-align: left;
    margin-top: -1rem;
  }

  .featureComparisonContainer {
    border: 1px black solid;
    box-shadow: 0.2rem 0.2rem #504b55;
    text-align: center;
    width: 21vw;
  }
  
  .subscriptionTypeContainer {
    margin-left: 17vw;
    margin-right: 17vw;
    text-align: center;
    display: grid;
    grid-template-columns: 0.25fr 0.25fr 0.25fr;
    grid-gap: 1.5rem;
  }

  .seasonPassContainer {
    border: 1px black solid;
    background-color: #F0F3F4;
    color: #000055;
    box-shadow: 0.2rem 0.2rem #504b55;
    text-align: center;
    width: 21vw;
    margin-right: 20vw;
  }

  .dayPassContainer {
    border: 1px black solid;
    background-color: #000055;
    color: white;
    box-shadow: 0.2rem 0.2rem #504b55;
    text-align: center;
    width: 21vw;
  }

  #dayPassHeader {
    margin-bottom: 3vh;
  }

  #color-key {
    padding-right: 0.5rem;
  }
  
  #elite {
    color: lightblue;
    -webkit-text-stroke: 0.015rem black;
    padding-right: 1rem;
  }
  
  #great {
    color: lightgreen;
    -webkit-text-stroke: 0.015rem black;
    padding-right: 1rem;
  }
  
  #above-average {
    color: #ffff00;
    -webkit-text-stroke: 0.017rem black;
    padding-right: 1rem;
  }
  
  #poor {
    color: lightpink;
    -webkit-text-stroke: 0.015rem black;
    padding-right: 1rem;
  }
  
  #sortableColor {
    color: #bf94e4;
    -webkit-text-stroke: 0.015rem black;
  }

  .sortable:hover {
    cursor: pointer;
  }
  
  .sortable {
    background-color: #d0b9e4;
  }
/* 
  ul, li {
    list-style: none;
  } */

  .notPremium {
    position: relative;
    top: 3.5rem;
    text-align: center;
    color: #000055;
  }
}
